<template>
  <div>
    <v-row>
      <v-col cols="2">
        <date-picker
          :value="fromDate"
          label="Van"
          :dateFormatted="fromDateFormatted"
          @changeDate="changeFromDate"
        ></date-picker>
      </v-col>
      <v-col cols="2">
        <date-picker
          :value="toDate"
          label="t/m"
          :dateFormatted="toDateFormatted"
          @changeDate="changeToDate"
        ></date-picker>
      </v-col>
      <v-col cols="3">
        <search-employee
                :searchString="searchEmployeeString"
                :noResults="false"
                @setSearchString ="setSearchEmployeeString"
                @get-results="selectEmployee"
                @clear-input="clearEmployee"
              ></search-employee></v-col>
      <v-col cols="2" class="align-self-center">
        <v-btn @click="submit" color="primary">Zoeken</v-btn>
      </v-col>
    </v-row>
    <v-data-table :headers="timesheetHeaders" :items="timesheets">
      <template v-slot:[`item.checkIn`]="{ item }">
        {{
          item.checkIn
            ? new Date(item.checkIn).toLocaleTimeString("nl-NL", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
              })
            : ""
        }}
      </template>
      <template v-slot:[`item.checkOut`]="{ item }">
        {{
          item.checkOut
            ? new Date(item.checkOut).toLocaleTimeString("nl-NL", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
              })
            : ""
        }}
      </template>
      <template v-slot:[`item.checkInPhoto`]="{ item }">
        <img height="100" :src="`data:image/png;base64,${item.checkInPhoto}`"
      /></template>
      <template v-slot:[`item.checkOutPhoto`]="{ item }">
        <img height="100" :src="`data:image/png;base64,${item.checkOutPhoto}`"
      /></template>
      <template v-slot:[`item.duration`]="{ item }">
        {{ msToTime(item.duration) }}
      </template>
    </v-data-table>
    {{ msToTime(totalDuration * 1000) }}
  </div>
</template>

<script>
import datePicker from "@/components/datePicker.vue"
import searchEmployee from '@/components/searchEmployee.vue'
import { fetchGET, fetchPOST } from "../js/functions"
export default {
  name: "timesheets",
  data() {
    return {
      fromDate: "",
      fromDateFormatted: "",
      toDate: "",
      toDateFormatted: "",
      searchEmployeeString: "",      
      timesheets: [],
      timesheetHeaders: [
        { text: "WerknemerID", value: "employeeNumber" },
        { text: "UID", value: "uid" },
        { text: "Check-in", value: "checkIn" },
        { text: "Check-uit", value: "checkOut" },
        { text: "Check-in foto", value: "checkInPhoto" },
        { text: "Check-uit foto", value: "checkOutPhoto" },
        { text: "Totaal", value: "duration" }
      ],
      options: {
        page: 1,
        itemsPerPage: 0,
        sortBy: ["employeeNumber"],
        sortDesc: [false]
      }
    }
  },
  methods: {
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    async fetchTimesheets() {
      let response = await fetchGET("fetchTimesheets", {
        options: this.options
      })
      console.log(response)
      this.timesheets = response[0].results
    },
    setSearchEmployeeString(){

    },
    selectEmployee(){

    },
    clearEmployee(){

    },
    async insertTimesheet() {
      let response = await fetchPOST("insertTimesheet", {
        employeeNumber: 1,
        uuid: "43 43 13 95 34 54 32",
        date: new Date("2021-08-19T04:38:20.202Z").toISOString(),
        checkIn: new Date("2021-08-19T04:38:20.202Z").toISOString(),
        checkOut: new Date("2021-08-19T04:38:20.202Z").toISOString(),
        photo: "img/photo1.png"
      })
      console.log(response)
    },
    msToTime(duration) {
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
      hours = hours < 10 ? "0" + hours : hours
      minutes = minutes < 10 ? "0" + minutes : minutes
      seconds = seconds < 10 ? "0" + seconds : seconds

      return hours + ":" + minutes + ":" + seconds
    },
    submit(){

    }
  },
  computed: {
    totalDuration() {
      return this.timesheets.reduce(
        (acc, cur) => acc + parseInt(cur.duration / 1000),
        0
      )
    }
  },
  mounted() {
    this.fetchTimesheets()
  },
  components: { datePicker,searchEmployee }
}
</script>

<style scoped>
</style>