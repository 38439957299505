var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{attrs:{"value":_vm.fromDate,"label":"Van","dateFormatted":_vm.fromDateFormatted},on:{"changeDate":_vm.changeFromDate}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{attrs:{"value":_vm.toDate,"label":"t/m","dateFormatted":_vm.toDateFormatted},on:{"changeDate":_vm.changeToDate}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('search-employee',{attrs:{"searchString":_vm.searchEmployeeString,"noResults":false},on:{"setSearchString":_vm.setSearchEmployeeString,"get-results":_vm.selectEmployee,"clear-input":_vm.clearEmployee}})],1),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submit}},[_vm._v("Zoeken")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.timesheetHeaders,"items":_vm.timesheets},scopedSlots:_vm._u([{key:"item.checkIn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.checkIn ? new Date(item.checkIn).toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit", hour12: false }) : "")+" ")]}},{key:"item.checkOut",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.checkOut ? new Date(item.checkOut).toLocaleTimeString("nl-NL", { hour: "2-digit", minute: "2-digit", hour12: false }) : "")+" ")]}},{key:"item.checkInPhoto",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"height":"100","src":("data:image/png;base64," + (item.checkInPhoto))}})]}},{key:"item.checkOutPhoto",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"height":"100","src":("data:image/png;base64," + (item.checkOutPhoto))}})]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.msToTime(item.duration))+" ")]}}],null,true)}),_vm._v(" "+_vm._s(_vm.msToTime(_vm.totalDuration * 1000))+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }